import {
  MeterProvider,
  PeriodicExportingMetricReader,
} from '@opentelemetry/sdk-metrics';
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http';
import { Attributes, Counter, Histogram } from '@opentelemetry/api';

const collectorOptions = {
  url: process.env.REACT_APP_OTEL_METRIC_COLLECTOR_URL,
  concurrencyLimit: 1,
};

const metricExporter = new OTLPMetricExporter(collectorOptions);
const meterProvider = new MeterProvider({
  readers: [
    new PeriodicExportingMetricReader({
      exporter: metricExporter,
      exportIntervalMillis: 30000,
    }),
  ],
});
const meter = meterProvider.getMeter('pre-crime');

enum MetricType {
  COUNTER = 'counter',
  HISTOGRAM = 'histogram',
}

const METRIC_SOURCE = 'agent_portal';

interface InstrumentDefinition {
  name: string;
  type: MetricType;
  description: string;
}

export enum INSTRUMENTS {
  EVENT_VERIFICATION_START = 'pcs.event_started_verification',
  EVENT_VERIFICATION_END = 'pcs.event_ended_verification',
  EVENT_VERIFICATION_DURATION = 'pcs.event_verification_duration',
}

// Create instruments for everything we want to track
const INSTRUMENT_DEFINITIONS: InstrumentDefinition[] = [
  {
    name: INSTRUMENTS.EVENT_VERIFICATION_START,
    type: MetricType.COUNTER,
    description:
      'When a specialist starts verifying an event (enters verify and respond page)',
  },
  {
    name: INSTRUMENTS.EVENT_VERIFICATION_END,
    type: MetricType.COUNTER,
    description:
      'When a specialist stopped verifying the event (leaves verify and respond page)',
  },
  {
    name: INSTRUMENTS.EVENT_VERIFICATION_DURATION,
    type: MetricType.HISTOGRAM,
    description: 'Elapsed milliseconds that the event was in Verify-Respond',
  },
];

const counterInstruments: { [key: string]: Counter } =
  INSTRUMENT_DEFINITIONS.reduce((accumulator, current) => {
    if (current.type === MetricType.COUNTER) {
      accumulator[current.name] = meter.createCounter(current.name, {
        description: current.description,
      });
    }
    return accumulator;
  }, {} as { [key: string]: Counter });

const histogramInstruments: { [key: string]: Histogram } =
  INSTRUMENT_DEFINITIONS.reduce((accumulator, current) => {
    if (current.type === MetricType.HISTOGRAM) {
      accumulator[current.name] = meter.createHistogram(current.name, {
        description: current.description,
      });
    }
    return accumulator;
  }, {} as { [key: string]: Histogram });

// The destination of Metrics is Grafana
// Attributes (tags) MUST be low-cardinality
interface Metric {
  name: string;
  value: number;
  attributes?: Attributes;
}

export const useAddCounter = () => {
  return (metric: Metric) => {
    counterInstruments[metric.name].add(metric.value, {
      ...metric?.attributes,
      source: METRIC_SOURCE,
    });
  };
};

export const useRecordMetric = () => {
  return (metric: Metric) => {
    histogramInstruments[metric.name].record(metric.value, {
      ...metric?.attributes,
      source: METRIC_SOURCE,
    });
  };
};
